html, body{
  height: 100%;
}
body {  
  font-family: "Montserrat", "Aller", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1c1e21;
}

h1 {
  font-size: 2.1rem;
  font-weight: bold;
}

p {
  font-size: 1rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#footer{
  margin-top: 100px;
  padding-top: 50px;
  padding-bottom: 50px;
}
.footer-social {
  margin-left: 5px;
  margin-right: 5px;
}
.footer-social, .footer-link {
  color: rgba(0,0,0,0.65);
  text-decoration: none;
}
.footer-social:active, .footer-link:active  {
  color: rgba(0,0,0,0.8);
  text-decoration: none;
}
.footer-social:hover, .footer-link:hover {
  color: rgba(0,0,0,1);
  text-decoration: underline;
  text-underline-offset: .3rem;
}

.navbar {
  --bs-bg-opacity: .9;
  border-bottom: 1px solid rgba(0,0,0,0.1);
}
.nav-link {
  font-size: .9rem;
  color: rgba(0,0,0,0.65);
}
.nav-link:active {
  color: rgba(0,0,0,0.8);
}
.nav-link:hover {
  text-decoration: underline;
  text-decoration-color: rgb(255, 32, 78, 1);
  text-underline-offset: .3rem;
}
.navbar-brand, span {
  font-size: 1.0rem;
}

.header{
  max-width: 700px;
  padding-top: 150px;
  padding-bottom: 150px;
}
.max-width
{
  max-width: 700px;
}
#gamePageHeader{
  padding-bottom: 50px;
}

.focus{
  color: rgb(255, 32, 78);
  font-weight: bold;
}

.call-out-container{
  position: flex;
  align-items: center;
  justify-content: center;
}
.call-out {    
  text-decoration: none;
  font-weight: 800;
  font-size: 1rem;
  color: rgb(255, 32, 78, 1);  
}
.call-out-icon {
  margin-left: 5px;
}
@keyframes moveBackAndForth {
  0%, 100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(5px); /* Move forward */
  }
  50% {
    transform: translateX(-2px); /* Move back */
  }
  75% {
    transform: translateX(3px); /* Move forward (less distance) */
  }
}
.call-out:active, .call-out:hover {
  color: rgb(235, 32, 73, 1);
  text-decoration: none;
}
.call-out:hover .call-out-icon {
  animation: moveBackAndForth 0.5s ease-in-out;
}


#game-highlight-cards{
  margin-top: 50px;
}
.game-card-body{
  background-color: rgba(0,0,0,0.02);
}
.card {
  width: 20rem;
}
.card-text{
  font-size: .9rem;
}
.homepage-card{
  border: none;
}
.homepage-card>img{
  width: 250px;
  border-radius: 10%;
}

.donwloadImg{
  max-width: 100%;
}